<template>
  <fw-layout :back-to="backToPath" mobile-ready :main-sidebar="false" loading-title="UC Competitions">
    <template #main-content>
      <div class="xl:max-w-screen-lg mx-auto px-2 md:px-5 flex flex-col gap-5">
        <BannerTournament
          v-if="!loading && tournament != null && league != null && edition != null && sport != null"
          :edition="edition"
          :league="league"
          :sport="sport"
          :tournament="tournament"
          :num-teams="sport?.stats?.approved_teams ?? 0"
          :num-players="sport?.stats?.total_players ?? 0"
        />
        <div v-else class="h-80 rounded-3xl bg-gray-200 animate-pulse"></div>

        <fw-panel v-if="tournament && tournament.description[language]" title="Sobre o torneio">
          <div class="content text-sm" v-html="tournament.description[language]" />
        </fw-panel>

        <fw-panel v-if="stage" :title="tournamentsStages[stage.stage]?.[language]">
          <p class="font-semibold text-gray-700">{{ typesList[stage.type]?.[language] ?? $t('notDefined') }}</p>
          <p class="text-gray-700">
            {{
              teamGroups[stage.team_group]
                ? `${teamGroups[stage.team_group]?.organic_unit?.name} - ${teamGroups[stage.team_group]?.name}`
                : ''
            }}
          </p>
        </fw-panel>

        <fw-panel v-if="team != null" title="A tua equipa" featured class="my-5">
          <div
            class="flex p-3 w-full gap-6 bg-white rounded-2xl h-16 items-center mt-4 cursor-pointer"
            @click="goToTeam(team)"
          >
            <div class=" w-24 relative h-16">
              <TeamAvatar :team="team" class="absolute -top-4 left-2" />
            </div>
            <div class="flex-1 flex justify-start">
              <div
                class="font-bold text-center border-2 rounded-full px-4 py-0.5"
                :style="{
                  color: team.color ?? '#777777',
                  borderColor: team.color ?? '#777777',
                }"
              >
                <v-clamp v-if="team.name != null && team.name.length > 0" autoresize :max-lines="1">
                  {{ team.name }}</v-clamp
                >
                <span v-else class="overflow-ellipsis overflow-hidden whitespace-nowrap">Sem nome</span>
              </div>
            </div>

            <div class="flex items-center justify-end gap-2">
              <div v-for="(player, p) in firstFourPlayers" :key="'player_' + p">
                <fw-avatar v-if="users[player]" size="xs" :user="users[player]" />
              </div>
              <div v-if="playersExcess > 0" class="text-xs text-gray-500">+{{ playersExcess }}</div>
            </div>

            <div v-if="team.status === 'approved'" class="text-sm text-primary font-semibold flex gap-1 items-center">
              Aprovada
              <fw-icon-shield-check class="h-10 w-10 text-primary" />
            </div>
            <div
              v-else-if="team.status === 'submitted'"
              class="text-sm text-red-600 font-semibold flex gap-1 items-center"
            >
              Aguarda aprovação
              <fw-icon-exclamation-triangle class="h-10 w-10 text-red-500" />
            </div>
            <div
              v-else-if="team.status === 'canceled'"
              class="text-sm text-gray-500 font-semibold flex gap-1 items-center"
            >
              Cancelada
            </div>
            <div>
              <fw-button type="link" size="sm"> Ver equipa </fw-button>
            </div>
          </div>
        </fw-panel>

        <PanelChallongeIframe :challonge="stage?.challonge" featured class="my-5"></PanelChallongeIframe>

        <PanelTeams :teams="teams" :loading="loading" :is-tournament="true" />

        <fw-panel-info debug label="Data (raw)">
          <json-viewer :value="{ league, team, edition, sport, tournament, stage }"></json-viewer>
        </fw-panel-info>

        <BannerSportUC />
      </div>
    </template>
  </fw-layout>
</template>

<script>
import PanelChallongeIframe from '@/components/panels/PanelChallongeIframe'
import BannerTournament from '@/components/banners/BannerTournament'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelTeams from '@/components/panels/PanelTeams.vue'
import BannerSportUC from '@/components/banners/BannerSportUC.vue'
import TeamAvatar from '@/components/TeamAvatar.vue'
import { TOURNAMENT_STAGES, TOURNAMENT_TYPES } from '@/utils/index.js'

export default {
  name: 'Tournament',
  components: {
    BannerTournament,
    PanelChallongeIframe,
    PanelTeams,
    BannerSportUC,
    TeamAvatar,
  },

  data() {
    return {
      loading: true,
      tournament: null,
      league: null,
      edition: null,
      team: null,
      teams: [],
      users: {},
      sport: null,
      timer: null,
      teamGroups: {},
      tournamentsStages: TOURNAMENT_STAGES,
      typesList: TOURNAMENT_TYPES,
    }
  },

  computed: {
    firstFourPlayers() {
      return this.team?.players?.slice(0, 4) ?? []
    },

    playersExcess() {
      return this.team?.players?.length - 4 ?? 0
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    tournamentKey() {
      return this.$route.params.tournamentKey
    },

    canSignup() {
      return this.tournament?.validations?.can_signup
    },

    gender() {
      return this.tournament.gender == 'U' ? 'Unisexo' : this.tournament.gender == 'F' ? 'Feminino' : 'Masculino'
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    view() {
      return this.$route.meta.view
    },

    leagueKey() {
      return this.$route.params.key
    },

    stageKey() {
      return this.$route.params.stageKey
    },

    stage() {
      if (!this.tournament?.stages) return
      return Object.values(this.tournament?.stages)
        .flat()
        ?.find(el => el.key == this.stageKey)
    },

    backToPath() {
      return this.$router.resolve({
        name: 'tournament-view',
        params: {
          editionKey: this.editionKey,
          key: this.leagueKey,
          sportKey: this.sport?.key,
          tournamentKey: this.tournamentKey,
        },
      }).href
    },
  },

  mounted() {
    this.getTournament()
    this.getTeamGroups()
  },

  methods: {
    async getTournament() {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTournament(this.tournamentKey)
          console.log('getTournament :>> ', response)
          this.tournament = response.tournament
          this.getLeagueDetails()
          this.teams = response.teams
          this.users = response.users
          this.team = response.team
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 750)
        }
      )
    },

    async getLeagueDetails() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getLeagueDetails(this.editionKey, this.leagueKey)
        console.log('getLeagueDetails :>> ', response)
        this.edition = response.edition
        this.league = response.league
        this.sport = response.league.sports.find(el => el.key == this.tournament.sport_key)
      })
    },

    goToTeam(team) {
      console.log('team :>> ', team)
      if (team && team.invites?.key) {
        return this.$router.push({
          name: 'team-invite',
          params: { tournamentKey: team.tournament_key, teamKey: team.key, inviteKey: team?.invites?.key },
        })
      }

      if (team && team.stats != 'draft_1') {
        return this.$router.push({
          name: 'team-view',
          params: { tournamentKey: team.tournament_key, teamKey: team.key },
        })
      }

      if (team) {
        return this.$router.push({
          name: 'team-signup-edit',
          params: {
            teamKey: team.key,
            tournamentKey: team.tournament_key,
          },
        })
      }
    },

    async getTeamGroups() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
          with_org_units: true,
        })
        console.log('getTeamGroups :>> ', response)
        this.teamGroups = {}

        for (let index = 0; index < response.groups.length; index++) {
          const teamGroup = response.groups[index]
          this.teamGroups[teamGroup.key] = teamGroup
        }
      })
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "notDefined": "Não definido",
      "createdAt": "Criado em",
      "startsAt": "Início das inscrições",
      "endsAt": "Fim das inscrições",
      "publishedOn": "Publicado em",
      "notFound": "Torneio não encontrado"
    },
    "en": {
      "notDefined": "Not defined",
      "createdAt": "Created at",
      "startsAt": "Signups Start Date",
      "endsAt": "Signups End Date",
      "publishedOn": "Published on",
      "notFound": "Tournament not found"
    }
  }
  </i18n>

<style>
.bg-challonge {
  background-color: #272a33;
}
</style>
